/* eslint-disable max-len */
import React from "react";

const ShowPasswordIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M22.7742 11.7527C21.9188 9.54011 20.4339 7.62663 18.5028 6.24879C16.5718 4.87095 14.2793 4.08914 11.9088 4C9.53825 4.08914 7.24579 4.87095 5.31475 6.24879C3.3837 7.62663 1.89872 9.54011 1.04333 11.7527C0.985558 11.9125 0.985558 12.0875 1.04333 12.2473C1.89872 14.4599 3.3837 16.3734 5.31475 17.7512C7.24579 19.1291 9.53825 19.9109 11.9088 20C14.2793 19.9109 16.5718 19.1291 18.5028 17.7512C20.4339 16.3734 21.9188 14.4599 22.7742 12.2473C22.832 12.0875 22.832 11.9125 22.7742 11.7527ZM11.9088 18.5455C8.05424 18.5455 3.98151 15.6873 2.50515 12C3.98151 8.31273 8.05424 5.45455 11.9088 5.45455C15.7633 5.45455 19.8361 8.31273 21.3124 12C19.8361 15.6873 15.7633 18.5455 11.9088 18.5455Z"
      fill="#9B9B9B"
      stroke="#9B9B9B"
      strokeWidth="0.3"
    />
    <path
      d="M11.9088 7.63635C11.0457 7.63635 10.2021 7.89228 9.48447 8.37176C8.76687 8.85124 8.20757 9.53275 7.8773 10.3301C7.54703 11.1274 7.46061 12.0048 7.62898 12.8513C7.79736 13.6978 8.21295 14.4753 8.82322 15.0855C9.43348 15.6958 10.211 16.1114 11.0575 16.2798C11.9039 16.4482 12.7813 16.3617 13.5787 16.0315C14.376 15.7012 15.0575 15.1419 15.537 14.4243C16.0165 13.7067 16.2724 12.863 16.2724 12C16.2724 10.8427 15.8127 9.73277 14.9943 8.91443C14.176 8.09609 13.0661 7.63635 11.9088 7.63635ZM11.9088 14.9091C11.3334 14.9091 10.771 14.7385 10.2926 14.4188C9.81417 14.0992 9.44131 13.6448 9.22112 13.1132C9.00094 12.5817 8.94333 11.9968 9.05558 11.4325C9.16783 10.8681 9.44489 10.3498 9.85174 9.94295C10.2586 9.53611 10.7769 9.25904 11.3412 9.1468C11.9055 9.03455 12.4905 9.09216 13.022 9.31234C13.5536 9.53252 14.0079 9.90539 14.3276 10.3838C14.6472 10.8622 14.8179 11.4246 14.8179 12C14.8179 12.7715 14.5114 13.5115 13.9658 14.057C13.4202 14.6026 12.6803 14.9091 11.9088 14.9091Z"
      fill="#9B9B9B"
      stroke="#9B9B9B"
      strokeWidth="0.3"
    />
  </svg>
);

export default ShowPasswordIcon;
