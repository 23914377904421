export enum ROLE_SHORT_CODE {
  SUPER_ADMIN = "SA",
  SUPER_EMPLOYEE = "SE",
  OEM_ADMIN = "OEMA",
  OEM_EMPLOYEE = "OEME",
  DRIVER = "DR",
  EV_OWNER = "EVO",
  FLEET_ADMIN = "FA",
  FLEET_EMPLOYEE = "FE",
  DEALER_ADMIN = "DA",
  DEALER_EMPLOYEE = "DE",
  BMS_ADMIN = "BMSA",
  BMS_EMPLOYEE = "BMSE",
  MCU_ADMIN = "MCUA",
  MCU_EMPLOYEE = "MCUE",
  VCU_ADMIN = "VCUA",
  VCU_EMPLOYEE = "VCUE",
  GPS_ADMIN = "GPSA",
  GPS_EMPLOYEE = "GPSE",
  OBC_ADMIN = "OBCA",
  OBC_EMPLOYEE = "OBCE",
  DCDC_ADMIN = "DCDCA",
  DCDC_EMPLOYEE = "DCDCE",
  BTMS_ADMIN = "BTMSA",
  BTMS_EMPLOYEE = "BTMSE",
  TPMS_ADMIN = "TPMSA",
  TPMS_EMPLOYEE = "TPMSE",
}

export enum OEM_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
}

export const oemStatus: Record<string, any> = {
  Inactive: 0,
  Active: 1,
};

export const faultComponents: Record<string, string> = {
  BMS: "BMS",
  VCU: "VCU",
  MCU: "MCU",
  EV: "EV",
};

export const faultTypes: Record<string, string> = {
  Component: "Component",
  System: "System",
};

export const faultCategories: Record<string, string> = {
  Alert: "Alert",
  Fault: "Fault",
};

export const ticketStatus: Record<string, number> = {
  Closed: 6,
  New: 1,
  "In-Progress": 2,
  Hold: 3,
  Reopen: 4,
  Deleted: 5,
};

export enum SEVERITY {
  VERY_GOOD = 1,
  GOOD = 2,
  AVERAGE = 3,
  BAD = 4,
  WORST = 5,
}

export const severityObj: Record<string, number> = {
  "Very Good": 1,
  Good: 2,
  Average: 3,
  Bad: 4,
  Worst: 5,
};

export enum RULE_TYPE {
  TELEMETRY = 1,
  SHADOW = 2,
  SYSTEM_GENERATED = 3,
  AI = 4,
}

export const ruleTypeObj: Record<string, number> = {
  Telemetry: 1,
  Shadow: 2,
  "System Generated": 3,
  AI: 4,
};

export enum RULE_STATUS {
  INACTIVE = 0,
  ACTIVE = 1,
  DELETED = 2,
}

export const ruleStatusObj: Record<string, number> = {
  Inactive: 0,
  Active: 1,
  Deleted: 2,
};

export enum CONDITION_TYPE {
  AND = 1,
  OR = 2,
}

export enum CONDITION_OPERATOR {
  LESS_THEN = "<",
  LESS_THEN_OR_EQUAL = "<=",
  GREATER_THEN = ">",
  GREATER_THEN_OR_EQUAL = ">=",
  EQUALS = "==",
}

export enum SHADOW_FIELDS {
  THERMAL_TYPE = "TTy",
  CHEMISTRY_TYPE = "CTy",
  CYCLE = "Cycl",
  CAPACITY = "Cap",
  CELL_CONFIGURATION = "CCfg",
  CELL_UNITS = "CUn",
  CELL_CHEMISTRY = "CChem",
  MOTER_TYPE = "MTy",
}

export enum TELEMETRY_FIELDS {
  VOLTAGE = "Volt",
  CURRENT = "BCur", // Is it BMS OR MCU Current If its BMS set value to BCur and If MCU set MCur
  SOC = "Soc",
  SOH = "Soh",
  BATTERY_TEMPRATURE = "BTemp",
  CYCLE_CNT = "CyCnt",
  State = "State",
  MINIMUM_DISCHARGE_VOLTAGE = "MinDchV",
  MAXIMUM_CHARGE_VOLTAGE = "MaxChgV",
  MCU_TEMPERATURE = "MTemp",
  EM_TEMPERATURE = "EmTemp",
  EM_RPM = "EmRpm",
  TORQUE = "Trq",
  VEHICLE_STATE = "VehSt",
  FAULT_SEVERITY = "FltSev",
  SPEED = "Spd",
  ESTIMATION_RANGE = "EstRng",
  ODOMETER = "Odo",
  TRIP_DISTANCE = "TripD",
  CURRENT_MODE = "CurMd",
  DRIVE_MODE = "DrvMd",
  WATT_HOURS_PER_KM = "WhpKm",
  DRIVE_POWER = "DrvPwr",
  REGEN_POWER = "Rgnpwr",
  ESTIMATION_WEIGHT = "EstWght",
  POWERTRAIN_EFFICIENCY = "PtEff",
}

export enum ACK_STATUS {
  PENDING = 1,
  SUCCESS = 2,
  FAILED = 3,
}

export enum COMMAND_TYPES {
  DEVICE = 1,
  OTA = 2,
  SYSTEM = 3,
}

export enum COMMAND_FOR_TYPES {
  DEVICE_COMMAND = "DEVICE_COMMAND",
  RULE_COMMAND = "RULE_COMMAND",
  OTA_COMMAND = "OTA_COMMAND",
}
