import Link from "next/link";
import { useRouter } from "next/router";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { ThreeDots } from "react-loader-spinner";
import Gleap from "gleap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination } from "swiper";
import Button from "../CommonSection/Buttons/Button";
import Input from "../CommonSection/Input/Input";
import classes from "./Login.module.scss";
import { login } from "../../lib/api/authApi";
import { login as loginAction } from "../../Store/Slices/AuthSlice";
import { getMasterData as getMasterDataAction } from "../../Store/Slices/MasterSlice";
import { setCookie } from "../../utils/general";
import { getMasterData } from "../../lib/api/generalApi";
import { errorToast } from "../../utils/toast";
import { ROLE_SHORT_CODE } from "../../utils/enums";
import HidePasswordIcon from "../../Icons/HidePasswordIcon";
import ShowPasswordIcon from "../../Icons/ShowPasswordIcon";
import LoginWelcomeContentOne from "./WelcomeContentOne";
import LoginWelcomeContentTwo from "./WelcomeContentTwo";

const initialValues = {
  email: "",
  password: "",
};

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Please enter your Email").email("Please enter valid email"),
  password: Yup.string().required("Please enter a Password"),
});

const Login: React.FC = (): JSX.Element => {
  const router = useRouter();
  const dispatch = useDispatch();
  const loginMutation = useMutation(login);
  const [passwodrdInputType, setPasswodrdInputType] = useState("password");
  const [isLoading, setLoading] = useState(false);
  const countryData = useQuery({
    queryKey: ["getMasterData"],
    queryFn: () => getMasterData(),
    onSuccess: (data) => {
      dispatch(getMasterDataAction(data.data.data));
      router.push("/dashboard");
    },
    enabled: false,
  });
  const { values, errors, touched, handleSubmit, handleBlur, handleChange } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (fvalues): Promise<void> => {
      const buttonEle = document.getElementById("login_submit") as HTMLButtonElement;
      buttonEle.disabled = true;
      setLoading(true);
      const reqData = {
        email: fvalues.email,
        password: fvalues.password,
      };
      try {
        loginMutation.mutate(reqData, {
          onSuccess: (data) => {
            if (
              [ROLE_SHORT_CODE.SUPER_ADMIN, ROLE_SHORT_CODE.SUPER_EMPLOYEE].includes(
                data.data.data.profile.role_sc,
              )
            ) {
              const { token, refreshToken } = data.data.data.authentication;
              setCookie(token, refreshToken);
              dispatch(loginAction(data.data.data));
              const userData = data.data.data;
              Gleap.identify(userData?.id, {
                name: `${userData?.profile?.first_name ? userData?.profile?.first_name : ""} ${
                  userData?.profile?.last_name ? userData?.profile?.last_name : ""
                }`,
                email: userData?.email,
              });
              countryData.refetch();
              // router.push("/dashboard");
            } else {
              errorToast("Invalid User!");
            }
            setLoading(false);
            buttonEle.disabled = false;
          },
          onError: (error: any) => {
            setLoading(false);
            buttonEle.disabled = false;
            errorToast(error?.response?.data?.message || "Something went wrong!");
          },
        });
      } catch (err) {
        buttonEle.disabled = false;
        errorToast("Something went wrong!");
      }
    },
  });

  return (
    <div className={`${classes.login_section}`}>
      <div className={`${classes.login_container}`}>
        <div className={`${classes.login_box}`}>
          <div className="w-full h-full bg-[url('/image/loginBg.png')] bg-cover bg-bottom bg-no-repeat">
            <Swiper
              modules={[Pagination, Autoplay]}
              slidesPerView={1}
              autoplay={{
                delay: 3000,
              }}
              pagination={{ clickable: true, type: "bullets" }}
              className="w-full h-[95%]"
            >
              <SwiperSlide>
                <LoginWelcomeContentOne />
              </SwiperSlide>
              <SwiperSlide>
                <LoginWelcomeContentTwo />
              </SwiperSlide>
            </Swiper>
          </div>
          <div className={`${classes.login_separates}`}>
            <div className={`${classes.login_card}`}>
              <h1 className={`${classes.login_title} font-display`}>Login To Your Account</h1>

              <div className={`${classes.login_form_separates}`}>
                <form onSubmit={handleSubmit} className="w-full flex flex-col items-center">
                  <div className={`${classes.login_details_separates}`}>
                    <Input
                      id="email"
                      label="Email"
                      placeholder="Enter an Email"
                      focused
                      type="text"
                      value={values.email}
                      error={errors.email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.email}
                    />
                    <Input
                      id="password"
                      label="Password"
                      placeholder="Enter the Password"
                      focused
                      type={passwodrdInputType}
                      value={values.password}
                      error={errors.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      touched={touched.password}
                      inputProps={{
                        endAdornment: (
                          <span
                            className="cursor-pointer"
                            role="button"
                            aria-hidden="true"
                            onClick={() => {
                              const inputType =
                                passwodrdInputType === "password" ? "text" : "password";
                              setPasswodrdInputType(inputType);
                            }}
                          >
                            {passwodrdInputType === "password" ? (
                              <ShowPasswordIcon />
                            ) : (
                              <HidePasswordIcon />
                            )}
                          </span>
                        ),
                      }}
                    />
                    <div className={`${classes.login_pw}`}>
                      <Link href="/forgot-password">Forgot Password?</Link>
                    </div>
                  </div>

                  <div className="mt-0 flex justify-center">
                    <Button
                      className="w-[268px] lg:h-[47px]"
                      variant="primary"
                      type="submit"
                      id="login_submit"
                    >
                      <ThreeDots
                        height="50"
                        width="50"
                        radius="9"
                        color="#fff"
                        ariaLabel="three-dots-loading"
                        visible={isLoading}
                      />
                      {!isLoading && "Login"}
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
