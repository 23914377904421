/* eslint-disable max-len */
import React from "react";

const HidePasswordIcon: React.FC = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1578_89597)">
      <path
        d="M17.5504 18.2926C15.841 19.5956 13.7595 20.3175 11.6104 20.3526C4.6104 20.3526 0.610397 12.3526 0.610397 12.3526C1.85429 10.0345 3.57953 8.00923 5.6704 6.41263M9.5104 4.59263C10.1987 4.4315 10.9035 4.35096 11.6104 4.35263C18.6104 4.35263 22.6104 12.3526 22.6104 12.3526C22.0034 13.4882 21.2795 14.5574 20.4504 15.5426M13.7304 14.4726C13.4557 14.7674 13.1245 15.0038 12.7565 15.1677C12.3886 15.3317 11.9913 15.4199 11.5885 15.427C11.1857 15.4341 10.7856 15.36 10.412 15.2091C10.0385 15.0582 9.69912 14.8337 9.41424 14.5488C9.12937 14.2639 8.90479 13.9246 8.75391 13.551C8.60302 13.1775 8.52892 12.7773 8.53603 12.3745C8.54314 11.9717 8.63131 11.5745 8.79527 11.2065C8.95924 10.8385 9.19565 10.5073 9.4904 10.2326"
        stroke="#9B9B9B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M0.610397 1.3526L22.6104 23.3526"
        stroke="#9B9B9B"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1578_89597">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default HidePasswordIcon;
